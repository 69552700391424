import React from 'react';
import { Link } from 'gatsby';


function TogetherBanner() {
  return (
    <div className="wrapper bg-white">
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-11 col-lg-6 d-flex align-items-center">
            <div>
              <h1 className="font-weight-bolder underscore">Let’s<br />fly together</h1>
              <h3 className="font-weight-lighter">
                Looking for a<br />
                Technology company<br />
                that understands<br />
                Innovation?
              </h3>
              <h4 className="font-weight-bold text-primary">
                <Link
                  to="/contact-us"
                  className="btn btn-lg btn-primary"
                >
                  Get in touch
                </Link>
              </h4>
            </div>
          </div>
          <div className="col-11 col-lg-6">
            <img src="/assets/kidfly.png" className="img-fluid d-none d-md-block" alt=" " />
            <img src="/assets/kidfly-sm.png" className="img-fluid d-block d-md-none" alt=" " />
          </div>
        </div>
      </div>
    </div>
  );
}

export default TogetherBanner;
